$(document).ready(function () {

    let cardsGridSliderOptions = {
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: true,
        infinite: true,
        dots: false,
        responsive: [
            {
                breakpoint: 768,
                settings: "unslick",
            }
        ]
    };

    $(".js-sliderCardsGrid").each(function () {
        let $slider = $(this);
        if ($(this).hasClass("inizialized")) {
            return;
        }

        if ($(window).width() < 768) {
            return;
        }

        if ($(this).find(".js-sliderCardsGrid__slide").length >= 2) {
            //filtro lo slider sulla base del contenuto della variabile window.userVisibility (impostata da react)
            $slider.on('init', function (event, slick) {
                setTimeout(() => {
                    filterSliderBasedOnGlobalVariable();
                },10)
            });

            $(this).slick(cardsGridSliderOptions);
            $(this).addClass("inizialized");
        }

    });

})